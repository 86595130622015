const { default: numbro } = require('numbro');
const { useTranslation } = require('react-i18next');

export const useRows = () => {
  const { t } = useTranslation();

  const rows = [
    {
      accessor: 'effectiveYield',
      name: t('components.SensitivityTable.yield'),
      Cell: (data) => <div style={{ direction: 'ltr', textAlign: 'right' }}>{numbro(data.effectiveYield * 100).format({ mantissa: 2 })}%</div>,
    },
    {
      accessor: 'gap',
      name: t('components.SensitivityTable.gap'),
      Cell: (data) => <div style={{ direction: 'ltr', textAlign: 'right' }}>{Math.floor(data.gap * 100 * 100)}</div>,
    },
    {
      accessor: 'sums.weightedDuration',
      name: t('components.SensitivityTable.duration'),
      Cell: (data) => <div style={{ direction: 'ltr', textAlign: 'right' }}>{numbro(data?.sums?.weightedDuration).format({ mantissa: 2 })}</div>,
    },
  ];

  return rows;
};
