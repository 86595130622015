import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { schema } from 'shared/schemas/sensitivityTable';

export const useSensitivityTableForm = ({ offer }) => {
  const auctionDetails = offer.initiation.auctionDetails;
  const defaultMinLimit = auctionDetails.sensitivityCalcParams.limitRange.min;
  const defaultMaxLimit = auctionDetails.sensitivityCalcParams.limitRange.max;
  const coupon = auctionDetails.coupon;
  const step = auctionDetails.biddingLimitations.priceGap;
  const auctionType = auctionDetails.type;

  const defaultValues = {
    minLimit: defaultMinLimit,
    maxLimit: defaultMaxLimit,
    step,
    coupon,
    auctionType,
    defaultMinLimit,
    defaultMaxLimit,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // get min and max limits from form
  const {
    watch,
    trigger,
    formState: { errors, isValidating },
  } = methods;
  const minLimit = watch('minLimit');
  const maxLimit = watch('maxLimit');
  const isValidState = !isValidating && isEmpty(errors);

  // Trigger the query validation when the min and max limits change
  useEffect(() => {
    trigger();
  }, [trigger, minLimit, maxLimit]);

  return { methods, isValidState, minLimit, maxLimit };
};
