import { useFormContext } from 'react-hook-form';

import { InputController } from '../Input/InputController';
import { InputControllerWrapper } from '../Input/InputControllerWrapper';

const { useTranslation } = require('react-i18next');

export const Header = ({ offer }) => {
  const { t } = useTranslation();

  const auctionType = offer.initiation.auctionDetails.type;

  // get min and max limits from form using react-hook-form
  const {
    formState: { errors },
  } = useFormContext();

  const minLimitController = (
    <InputController
      fieldName={'minLimit'}
      label={t(`components.SensitivityTable.form.labels.minLimit.${auctionType}`)}
      format={(v) => v}
      isEditingMode
    />
  );
  const maxLimitController = (
    <InputController
      fieldName={'maxLimit'}
      label={t(`components.SensitivityTable.form.labels.maxLimit.${auctionType}`)}
      format={(v) => v}
      isEditingMode
    />
  );

  return (
    <header className="flex justify-between items-center mt-2">
      <h2>{t('components.SensitivityTable.title')}</h2>
      <div className="flex gap-2">
        <InputControllerWrapper controller={minLimitController} isInvalid={errors.minLimit} errors={t(errors.minLimit?.message)} />
        <InputControllerWrapper controller={maxLimitController} isInvalid={errors.maxLimit} errors={t(errors.maxLimit?.message)} />
      </div>
    </header>
  );
};
