import dayjs from 'dayjs';

import { offerProgressCheckpoints, offerServerStatuses } from 'shared/const/offerCreationStages';

import { extractMandatorySignatoriesIds, extractSignatoriesIds, extractSignaturesIds } from './signatories';

/* Checks if the auction is currently active based on its status and closing time. */
export const isAuctionActive = (auction) => {
  if (!auction) {
    return false;
  }
  //TODO: why an auction that it's close time have passed is still active?
  return auction.status === 'ACTIVE' && dayjs(auction.closeTime).isAfter(dayjs());
};

/* Determines if an offer has been rejected, based on various rejection statuses. */
export const isRejectedByUnderwriter = (offer) => {
  if (!offer) {
    return false;
  }
  return offer.status === offerServerStatuses.REJECTED_BY_UNDERWRITER || offer.status === offerServerStatuses.BIDDING_ENDED_AUTO_REJECT;
};

/* Checks if an offer has been accepted by the underwriter. */
export const isAcceptedByUnderwriter = (offer) => {
  if (!offer) {
    return false;
  }
  return offer.status === offerServerStatuses.ACCEPTED_BY_UNDERWRITER;
};

/* Checks if an offer has any signatures. */
export const hasSignatures = (offer) => {
  if (!offer) {
    return false;
  }
  return offer.internalApproval?.signatures?.length > 0;
};

export const hasSignatoriesSigned = (offer) => {
  if (!offer) {
    return false;
  }

  return offer.progress?.[offerProgressCheckpoints.signatoriesSigned];
};

/* Checks if an offer is in the limitations and contacts stage. */
export const isLimitationsAndContactsFilled = (offer) => {
  if (!offer) {
    return false;
  }
  return offer.progress?.[offerProgressCheckpoints.limitationsAndContactsFilled];
};

/* Checks if an offer is ready for auctions. */
export const isReadyForAuctions = (offer) => {
  if (!offer) {
    return false;
  }
  return offer.status === offerServerStatuses.READY_TO_AUCTIONS || isAcceptedByUnderwriter(offer);
};

/* Determines if an offer has been sent to signatories. */
export const isSentToSignatories = (offer) => {
  if (!offer) {
    return false;
  }
  return offer.progress?.[offerProgressCheckpoints.sentToSignatories];
};

/* Checks if an offer is in editing mode. */
export const isEditingMode = (offer) => {
  if (!offer) {
    return false;
  }
  return offer.status === offerServerStatuses.EDITING_MODE;
};

/* Determines if an offer is complete, either ready for auctions, accepted, or rejected. */
export const isOfferComplete = (offer) => {
  if (!offer) {
    return false;
  }
  return isReadyForAuctions(offer) || isAcceptedByUnderwriter(offer) || isRejectedByUnderwriter(offer);
};

export const allowSendingWithMissingSignatures = (offer, userId) => {
  if (!(offer && userId)) {
    return false;
  }

  return (
    isUserOfferInitiator(offer, userId) &&
    !isOfferComplete(offer) &&
    !hasSignatoriesSigned(offer) &&
    hasSignatures(offer) &&
    hasMandatorySignatorySigned(offer)
  );
};

/* Checks if an offer document is ready to be viewed based on various criteria. */
export const isProposalDocumentReadyForView = (offer) => {
  if (!offer) {
    return false;
  }
  return hasSignatoriesSigned(offer) || isAcceptedByUnderwriter(offer) || isReadyForAuctions(offer) || isRejectedByUnderwriter(offer);
};

/* Checks if an offer is ready to be viewed based on various criteria. */
export const isOfferReadyForView = (offer) => {
  if (!offer) {
    return false;
  }
  return isProposalDocumentReadyForView(offer) || offerReadyForSigning(offer);
};

/* Determines if an offer is ready for signing. */
export const offerReadyForSigning = (offer) => {
  if (!offer) {
    return false;
  }
  return isSentToSignatories(offer) && !hasSignatoriesSigned(offer);
};

/* Checks if offer cancellation is allowed. */
export const allowOfferCancellation = (offer) => {
  if (!offer) {
    return false;
  }
  return isAuctionActive(offer?.auction) && !isOfferComplete(offer);
};

export const hasMandatorySignatorySigned = (offer) => {
  if (!offer) {
    return false;
  }

  const mandatorySignatoriesIds = extractMandatorySignatoriesIds(offer);
  // no mandatory signatories? no problem
  if (!mandatorySignatoriesIds.length) {
    return true;
  }

  const signaturesEmployeeIds = extractSignaturesIds(offer);
  return mandatorySignatoriesIds.some((mandatorySignatoryId) => {
    return !!signaturesEmployeeIds.find((signatoryId) => signatoryId === mandatorySignatoryId);
  });
};

/* Determines if the user is the initiator of the offer. */
export const isUserOfferInitiator = (offer, userId) => {
  if (!offer || !userId) {
    return false;
  }
  return offer?.initiation?.initiator?.userId === userId;
};

/* Checks if the user can cancel the offer. */
export const userCanCancelOffer = (offer, userId, isAdminAtCompany) => {
  if (!offer || !userId) {
    return false;
  }
  return isUserOfferInitiator(offer, userId) || isAdminAtCompany(offer?.initiation?.investingCompany?.companyId);
};

/* Checks if the user has signed the offer. */
export const userHasSigned = (offer, userId) => {
  if (!offer || !userId) {
    return false;
  }
  return offer?.internalApproval?.signatures?.some((signature) => signature.userId === userId);
};

/* Determines if the user is a signatory of the offer. */
export const userIsSignatory = (offer, userId) => {
  if (!offer || !userId) {
    return false;
  }
  return offer?.internalApproval?.signatories?.some((signatory) => signatory === userId);
};

/* Checks if the user is the only signatory of the offer. */
export const userIsOnlySignatory = (offer, userId) => {
  if (!offer || !userId) {
    return false;
  }
  const signatories = extractSignatoriesIds(offer);
  return signatories?.length === 1 && signatories[0] === userId;
};

export const userIsLastSignatory = (offer, userId) => {
  if (!offer || !userId) {
    return false;
  }

  const missingSignatureUsers = offer?.internalApproval?.signatories?.filter((signatory) => {
    return !offer?.internalApproval?.signatures?.some((signature) => signature.userId === signatory);
  });

  return userCanSign(offer, userId) && missingSignatureUsers.length === 1 && missingSignatureUsers[0].userId === userId;
};

/* Checks if the user can sign the offer. */
export const userCanSign = (offer, userId) => {
  if (!offer || !userId) {
    return false;
  }
  return (
    (offerReadyForSigning(offer) || isEditingMode(offer) || (isUserOfferInitiator(offer, userId) && isLimitationsAndContactsFilled(offer))) &&
    !userHasSigned(offer, userId) &&
    userIsSignatory(offer, userId)
  );
};

/* Checks if the user can send the offer. */
export const userCanSend = (offer, userId) => {
  if (!offer || !userId) {
    return false;
  }
  return isUserOfferInitiator(offer, userId) && hasSignatoriesSigned(offer) && !isOfferComplete(offer);
};

/* Checks if the user should quick send the offer. */
export const userShouldQuickSend = (offer, userId) => {
  if (!offer || !userId) {
    return false;
  }
  return (
    isUserOfferInitiator(offer, userId) && (userIsOnlySignatory(offer, userId) || userIsLastSignatory(offer, userId) || hasSignatoriesSigned(offer))
  );
};

/**
 * an offer can be sent through 'regular pipeline' and 'quick sending pipeline'
 * regular: after the offer is being filled completely, it is sent to all it's signatories, and after all of them approved, the initiator give it one last approval by clicking 'send'
 * quick sending: after the offer is being filled completely, if the initiator is the only signatory, the initiator can send the offer without notifying all signatories and waiting for their approvals
 */
export const userCanQuickSend = (offer, userId) => {
  if (!offer || !userId) {
    return false;
  }
  return !isOfferComplete(offer) && userShouldQuickSend(offer, userId) && hasSignatoriesSigned(offer);
};
