import { AccountsStructures } from 'shared/const/accountsStructures';
import { bankAccountTypes } from 'shared/const/bankAccounts';
import { buildNewTableLine, hashBankAccount } from 'shared/utils/offers';

export const generateBidsAndAccountsDefaultValues = (offer) => {
  const defaultValues = {};

  /* accounting type */
  const currentAccountsStructure = offer?.bankAccounts?.chosenType;
  const defaultAccountStructure = currentAccountsStructure || AccountsStructures.UNITED;
  defaultValues.accountsStructure = defaultAccountStructure;

  /* divided accounts */
  const currentDividedAccounts = offer?.bankAccounts.dividedAccounting;
  if (currentDividedAccounts?.length) {
    const dividedTableValuesArray = currentDividedAccounts.map((dividedAccountEntry) => {
      const { selectedCashAccount, selectedSharesAccount, amount, limit, accountName, maxPercentageFromAuction } = dividedAccountEntry;
      const { bank: cash_account_bank, branchNumber: cash_account_branch, accountNumber: cash_account_number } = selectedCashAccount;
      const { bank: shares_account_bank, branchNumber: shares_account_branch, accountNumber: shares_account_number } = selectedSharesAccount;
      return {
        cash_account_bank,
        cash_account_branch,
        cash_account_number,
        shares_account_bank,
        shares_account_branch,
        shares_account_number,
        account_name: accountName,
        limit: limit,
        amount: amount,
        maxPercentageFromAuction: maxPercentageFromAuction,
      };
    });
    defaultValues.table = dividedTableValuesArray;
  } else {
    defaultValues.table = [buildNewTableLine()];
  }

  /* united accounts */
  const currentUnitedAccounts = offer?.bankAccounts.unitedAccounting;
  if (currentUnitedAccounts) {
    const { selectedCashAccount, selectedSharesAccount } = currentUnitedAccounts;
    if (selectedCashAccount) {
      defaultValues[bankAccountTypes.cashAccount] = hashBankAccount(selectedCashAccount);
    }
    if (selectedSharesAccount) {
      defaultValues[bankAccountTypes.sharesAccount] = hashBankAccount(selectedSharesAccount);
    }
  }

  /* bids */
  const currentBids = offer?.bids;
  if (currentBids?.length) {
    currentBids.forEach((bid, index) => {
      const { limit, amount } = bid;
      defaultValues[`bid_${index}_limit`] = limit;
      defaultValues[`bid_${index}_amount`] = amount;
    });
  }

  /* trick watch update to overcome react-hook-form bug */
  /* watch is not updated when the value is an object and and inner value is changed */
  /* stringifying the object and saving it in a different field accomplishes the needed update */
  defaultValues.tableStr = JSON.stringify(defaultValues.table);

  return defaultValues;
};

export const generateLimitationAndContactsDefaultValues = (offer) => {
  if (!offer) return {};

  //todo: what is the meaning of '||undefined' here? (we only preventing falsy values by doing that, looks like a development mistake)
  const result = {
    isActive_maxPercentageFromAuction: !!offer.limitations.maxPercentageFromAuction,
    maxPercentageFromAuction: offer.limitations.maxPercentageFromAuction || undefined,
    isActive_issuedCapitalWinningPercentLimit: !!offer.limitations.issuedCapitalPresentHoldings,
    limit_issuedCapitalWinningPercentLimit: offer.limitations.issuedCapitalWinningPercentLimit || undefined,
    issuedCapitalPresentHoldings: offer.limitations.issuedCapitalPresentHoldings || undefined,
    isActive_minProceeds: !!offer.limitations.minProceeds,
    limit_minFundraisingVolume: offer.limitations.minProceeds || undefined,
    noUnderwriters: !!offer.limitations.forbidParticipatingUnderwriters,
    underwritersAtMinProfitOnly: !!offer.limitations.allowUnderwritersAtMinProfitOnly,
    additionalComment: offer.limitations.additionalComment || '',
    contactPerson: offer.contactInfo.representative || offer.initiation.initiator.userId,
    emailsForAuctionResults: offer.contactInfo.emailsForAuctionResults || [],
    sendAcceptanceSMS: offer.contactInfo.sendAcceptanceSMS,
  };

  // add mutual limitations fields
  const associates = offer?.initiation.investingCompany?.associates || [];
  const chosenAssociates = offer.limitations.mutualLimitation.associates || [];
  associates.forEach((associate) => {
    const associateIsChosen = chosenAssociates.some((chosenAssociate) => chosenAssociate.bnNumber === associate.bnNumber);
    result[`mutualLimitation_${associate.bnNumber}`] = associateIsChosen;
  });

  return result;
};
