import numbro from 'numbro';
import { useContext, useState } from 'react';

import { OfferCreationContext } from 'context/offerCreation';

import { auctionTypes } from 'shared/const/auctions';
import { BID_INPUT_FIELDS } from 'shared/const/bidInputFieldType';

import { useScopeAndAccountsErrors } from 'hooks/offers';

import { InputController } from '../Input/InputController';
import { InputControllerWrapper } from '../Input/InputControllerWrapper';

import { useBidInputValidation } from './useBidInputValidation';

export const BidInput = ({ inputType, index, disable = false, ...props }) => {
  const fieldName = `bid_${index}_${inputType}`;
  useBidInputValidation({ fieldName, inputType });

  const [isEditing, setIsEditing] = useState(false);
  const { setAtLeastOneBidTouched, offer } = useContext(OfferCreationContext);
  const { errorToPresent, errorDisplay } = useScopeAndAccountsErrors(fieldName);

  const auctionType = offer?.initiation?.auctionDetails?.type;
  const isPriceAuction = auctionType === auctionTypes.PRICE;

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleFocus = () => {
    setIsEditing(true);
    setAtLeastOneBidTouched(true);
  };

  const format = (value) => {
    switch (inputType) {
      case BID_INPUT_FIELDS.LIMIT:
        return isPriceAuction
          ? numbro(value).format('0,0$')
          : numbro(value).formatCurrency({
              thousandSeparated: true,
              currencySymbol: '%',
              currencyPosition: 'postfix',
            });
      case BID_INPUT_FIELDS.AMOUNT:
        return numbro(value).format({
          thousandSeparated: true,
          mantissa: 0,
        });
      default:
        return value;
    }
  };

  const controller = <InputController fieldName={fieldName} isEditingMode={isEditing} format={format} disable={disable} {...props} />;
  const isInvalid = !!errorToPresent;

  return (
    <td id={fieldName}>
      <InputControllerWrapper controller={controller} isInvalid={isInvalid} errors={errorDisplay} onBlur={handleBlur} onFocus={handleFocus} />
    </td>
  );
};
