import { faInfoCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'components/UI/Tooltip';

export const Header = ({ lastTimeUpdated, onRefreshClick }) => {
  const { t } = useTranslation();
  const time = dayjs(lastTimeUpdated).format('HH:mm');

  return (
    <header className="flex items-center gap-2">
      <h1 className="font-bold text-orange-500">{t('components.YieldAnalysis.header.title')}</h1>
      {/* <Badge bg="info">{t('common.new')}</Badge> */}
      <span className="bg-blue-400 text-black text-center px-2 rounded-lg font-bold">{t('common.new')}</span>
      <Tooltip Content={<div>{t('components.YieldAnalysis.header.tooltip')}</div>} Trigger={<FontAwesomeIcon color="gray" icon={faInfoCircle} />} />
      <span className="text-muted">{`${t('common.accurateForTime')}-${time}`}</span>
      <FontAwesomeIcon onClick={onRefreshClick} className="cursor-pointer" color="orange" icon={faRefresh} />
    </header>
  );
};
