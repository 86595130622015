import * as yup from 'yup';

import { auctionTypes } from 'shared/const/auctions';

import { calculateSteps } from 'components/Offers/SensitivityTable/utils';

const t = (key) => key; //just a mock to make the translation plugin show translations

export const schema = yup.object().shape({
  minLimit: yup
    .number()
    .required()
    .test('min', t('components.SensitivityTable.form.errors.minLimitTooSmall'), function (value) {
      switch (this.parent.auctionType) {
        case auctionTypes.INTEREST:
        case auctionTypes.PRICE:
          return value >= 0;
        case auctionTypes.GAP:
          return value >= this.parent.coupon * -1;
        default:
          return false;
      }
    })
    .test('minLimit', t('components.SensitivityTable.form.errors.minLimitAboveMaxLimit'), function (value) {
      return value <= this.parent.maxLimit;
    })
    .test('stepsBetween', t('components.SensitivityTable.form.errors.maxStepsBetweenLimits'), function (value) {
      const { minLimit, maxLimit, step } = this.parent;

      if (!minLimit || !maxLimit || !step) return true;

      const numOfStepsBetweenLimits = calculateSteps(minLimit, maxLimit, step).length;
      return numOfStepsBetweenLimits <= 50;
    }),
  maxLimit: yup
    .number()
    .required()
    .test('maxLimit', t('components.SensitivityTable.form.errors.maxLimitBelowMinLimit'), function (value) {
      return value >= this.parent.minLimit;
    })
    .test('max', t('components.SensitivityTable.form.errors.maxLimitTooBig'), function (value) {
      return this.parent.defaultMaxLimit * 2 >= value;
    }),
});
