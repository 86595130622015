export const extractSignatories = (offer) => {
  const signatories = offer?.internalApproval.signatories;
  const signatoriesWithDetails = signatories.map((signatoryId) =>
    offer?.initiation.investingCompany.employees.find((employee) => employee.userId === signatoryId),
  );
  return signatoriesWithDetails;
};

export const extractSignatoriesIds = (offer) => {
  const signatories = offer?.internalApproval.signatories;
  return signatories;
};

export const extractSignatures = (offer) => {
  return offer?.internalApproval.signatures;
};

export const extractSignaturesIds = (offer) => {
  const signatures = extractSignatures(offer);
  return signatures.map((signature) => signature.userId);
};

export const extractMandatorySignatories = (offer) => {
  const signatories = extractSignatories(offer);
  return signatories.filter((signatory) => signatory.isMandatorySignatory);
};

export const extractMandatorySignatoriesIds = (offer) => {
  const signatories = extractMandatorySignatories(offer);
  return signatories.map((signatory) => signatory.userId);
};

export const isSignatoryById = (offer, userId) => {
  const signatoriesIds = extractSignatoriesIds(offer);
  return signatoriesIds.includes(userId);
};
