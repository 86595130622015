import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Form, FormProvider } from 'react-hook-form';

import { OfferCreationContext } from 'context/offerCreation';

import { useOfferStateUtils } from 'hooks/offers';

import { MaxThreeLimitWarning, SplitConfirmModal, ValueWarning } from 'components/Offers/BidsAndAccountsModals';
import OfferAccountsInput from 'components/Offers/OfferAccountsInput/OfferAccountsInput';
import { Bids } from 'components/Offers/OfferVolumeInputTable/BidsTable';
import { YieldAnalysis } from 'components/Offers/YieldAnalysis';

import { useBidsAndAccountsForm } from './useBidsAndAccountsForm';
import { useBidsAndAccountsPopUps } from './useBidsAndAccountsPopUps';

export const BidsAndAccounts = () => {
  const { offer, showYieldAnalysis } = useContext(OfferCreationContext);
  const {
    valueWarningOpts,
    maxThreeLimitOpts,
    splitConfirmOpts,
    checkSplitsLimitsMismatch,
    checkDistinctLimitsForAuctionByCompany,
    checkValueWarning,
  } = useBidsAndAccountsPopUps();
  const { methods } = useBidsAndAccountsForm({ checkSplitsLimitsMismatch, checkDistinctLimitsForAuctionByCompany, checkValueWarning });
  const { isOfferComplete } = useOfferStateUtils({ offer });

  return (
    <>
      <FormProvider {...methods}>
        <Form>
          <fieldset disabled={isOfferComplete}>
            <SplitConfirmModal {...splitConfirmOpts} />
            <ValueWarning {...valueWarningOpts} />
            <MaxThreeLimitWarning {...maxThreeLimitOpts} />

            <Container className="grid grid-cols-1 lg:grid-cols-[5fr_3fr] gap-[1rem] pb-4">
              <div className="min-w-0">
                <OfferAccountsInput />
              </div>
              <div>
                <Bids />
              </div>
            </Container>
          </fieldset>
        </Form>
      </FormProvider>
      {showYieldAnalysis && <YieldAnalysis />}
    </>
  );
};
