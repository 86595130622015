import dayjs from 'dayjs';
import flatten from 'lodash/flatten';
import numbro from 'numbro';
import { useCallback } from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { BID_INPUT_FIELDS } from 'shared/const/bidInputFieldType';
import { offerServerStatuses } from 'shared/const/offerCreationStages';
import { buildAuctionTitleGeneric } from 'shared/utils/auctions';
import { calcOfferValue, formatBidValue } from 'shared/utils/offers';

export const Columns = (isActive) => {
  const { t } = useTranslation();

  const bidFieldCell = useCallback((index, field) => {
    return {
      Header: `${field + (index + 1)}`,
      accessor: `bids[${index}].${field}`,
      Cell: (row) => {
        const { bids } = row;
        if (bids.length <= index) {
          return <span>-</span>;
        }

        const value = bids[index][field];
        return <span>{formatBidValue(value, field, row)}</span>;
      },
      noSort: true,
    };
  }, []);

  const cols = [
    {
      Header: 'auctionName',
      accessor: 'initiation.auctionDetails.issuedCompanyName',
      Cell: (row) => {
        const { issuedCompanyName, unitStructures } = row.initiation.auctionDetails;
        const value = buildAuctionTitleGeneric({ issuedCompanyName, unitStructures, t });
        return <span>{value}</span>;
      },
    },
    {
      Header: 'auctionId',
      accessor: 'initiation.auctionDetails.auctionId',
      Cell: (row) => {
        return <span>{row.initiation.auctionDetails.auctionId.toString()}</span>;
      },
    },
    {
      Header: 'auctionDate',
      accessor: 'initiation.auctionDetails.closeTime',
      Cell: (row) => {
        const { closeTime } = row.initiation.auctionDetails;
        const value = dayjs(closeTime).format('DD/MM/YYYY');
        return <span>{value}</span>;
      },
    },
    {
      Header: 'offerId',
      accessor: '_id',
      Cell: (row) => {
        return <span>{row._id.toString()}</span>;
      },
      noSort: true,
    },
    {
      Header: 'status',
      accessor: 'status',
    },
    {
      Header: 'investingCompany',
      accessor: 'initiation.investingCompany.name',
    },
    {
      Header: 'initiatorName',
      accessor: 'initiation.initiator.firstName',
      Cell: (row) => {
        return <span>{`${row.initiation.initiator.firstName} ${row.initiation.initiator.lastName}`}</span>;
      },
    },
    {
      Header: 'offerValue',
      accessor: 'bids',
      Cell: (row) => {
        const value = calcOfferValue(row);
        return <span>{numbro(value).format('0,0$')}</span>;
      },
      exportAccessor: (row) => {
        const value = calcOfferValue(row);
        return numbro(value).format('0,0$');
      },
      noSort: true,
    },
  ];

  // the bid fields are only shown when the auction is not active
  // as this is sensitive information
  if (!isActive) {
    cols.push(
      ...flatten(
        [
          [BID_INPUT_FIELDS.LIMIT, BID_INPUT_FIELDS.AMOUNT], // limit1, amount1
          [BID_INPUT_FIELDS.LIMIT, BID_INPUT_FIELDS.AMOUNT], // limit2, amount2
          [BID_INPUT_FIELDS.LIMIT, BID_INPUT_FIELDS.AMOUNT], // limit3, amount3
        ].map((array, index) => {
          return array.map((field) => bidFieldCell(index, field));
        }),
      ),
    );
  }

  cols.push(
    {
      Header: 'underwriter',
      accessor: 'initiation.auctionDetails.underwriter.name',
    },
    {
      Header: 'underwriterActionStatus',
      accessor: 'status',
      Cell: (row) => {
        const { status } = row;
        switch (status) {
          case offerServerStatuses.ACCEPTED_BY_UNDERWRITER:
            return <Badge bg="success">{t('screens.auctions.table.badges.accepted')}</Badge>;
          case offerServerStatuses.REJECTED_BY_UNDERWRITER:
            return <Badge bg="danger">{t('screens.auctions.table.badges.rejected')}</Badge>;
          case offerServerStatuses.READY_TO_AUCTIONS:
            return <Badge bg="warning">{t('screens.auctions.table.badges.pending')}</Badge>;
          default:
            return '-';
        }
      },
      noSort: true,
      noExport: true,
    },
  );

  return cols;
};
