import { faBarsProgress, faCheck, faFileSignature, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { offerServerStatuses } from 'shared/const/offerCreationStages';

import { useOfferStateUtils } from 'hooks/offers';

export const StatusCell = ({ offer }) => {
  const { t } = useTranslation();
  const { userCanSign, isSentToSignatories, hasSignatoriesSigned } = useOfferStateUtils({ offer });

  const buildStatusCell = (offer) => {
    switch (offer.status) {
      case offerServerStatuses.INITIATED:
        if (hasSignatoriesSigned) {
          return (
            <span>
              <FontAwesomeIcon icon={faCheck} className="text-success" /> {t('components.LiveAuctionCard.statuses.ready_to_be_sent')}
            </span>
          );
        }

        if (isSentToSignatories) {
          const text = userCanSign ? t('components.LiveAuctionCard.statuses.sign') : t('components.LiveAuctionCard.statuses.signatures');

          return (
            <span>
              <FontAwesomeIcon icon={faFileSignature} className="text-warning" /> {text}
            </span>
          );
        }

        return (
          <span>
            <FontAwesomeIcon icon={faBarsProgress} className="text-warning" /> {t('components.LiveAuctionCard.statuses.initiated')}
          </span>
        );
      // sent successfully to the underwriter
      case offerServerStatuses.READY_TO_AUCTIONS:
      case offerServerStatuses.ACCEPTED_BY_UNDERWRITER:
        if (offer?.originalOfferId) {
          return (
            <span>
              <FontAwesomeIcon icon={faCheck} className="text-success" /> {t('components.LiveAuctionCard.statuses.updatedAndSent')}
            </span>
          );
        }

        return (
          <span>
            <FontAwesomeIcon icon={faCheck} className="text-success" /> {t('components.LiveAuctionCard.statuses.sent')}
          </span>
        );
      // something went wrong
      case offerServerStatuses.REJECTED_BY_SIGNATORIES:
      case offerServerStatuses.REJECTED_BY_UNDERWRITER:
        return (
          <span>
            <FontAwesomeIcon icon={faTriangleExclamation} className="text-danger" /> {t('components.LiveAuctionCard.statuses.rejected')}
          </span>
        );
      case offerServerStatuses.EDITING_MODE:
        return (
          <span>
            <FontAwesomeIcon icon={faBarsProgress} className="text-warning" /> {t('components.LiveAuctionCard.statuses.editMode')}
          </span>
        );
      default:
        return (
          <span>
            <FontAwesomeIcon icon={faTimes} className="text-danger" /> {t('components.LiveAuctionCard.statuses.notSent')}
          </span>
        );
    }
  };

  if (!offer) return null;

  return buildStatusCell(offer);
};
