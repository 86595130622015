import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useMemo } from 'react';
import { Container } from 'react-bootstrap';

import { StyledTable } from '../TableQuery/styles';

export const PivotTable = ({ data, columnKey, rowKeys, rowsColumnText, style, fixed = false, columnFormatter = (val) => val }) => {
  // Create a memoized pivot table data structure
  const pivotData = useMemo(() => {
    const table = {};
    const columnLabels = new Set();

    // Fill the table with values
    data?.forEach((item) => {
      const columnLabel = item[columnKey];
      columnLabels.add(columnLabel);

      table[columnLabel] = item;
    });

    return {
      columns: Array.from(columnLabels),
      table,
    };
  }, [data, columnKey]);

  const firstColumnClassName = useMemo(() => {
    if (!fixed) {
      return '';
    }

    // If the table is fixed, the first column should be sticky
    return `sticky start-0 z-5`;
  }, [fixed]);

  const generateSingleCell = useCallback(
    (row, column, index) => {
      const { Cell, accessor } = row;
      const columnData = pivotData.table[column];
      const cellContent = isFunction(Cell) ? Cell(columnData) : get(columnData, accessor);
      const result = <td key={index}>{cellContent}</td>;
      return result;
    },
    [pivotData.table],
  );

  return (
    <Container>
      <StyledTable responsive borderless hover style={{ ...style }}>
        <thead>
          <tr>
            <th className={firstColumnClassName}>{rowsColumnText}</th>
            {pivotData.columns.map((col) => (
              <th key={col}>{columnFormatter(col)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowKeys.map((row) => (
            <tr key={row.accessor}>
              <td className={firstColumnClassName}>{row.name}</td>
              {pivotData.columns.map((col, idx) => generateSingleCell(row, col, idx))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Container>
  );
};
