/* OFFER CREATION SCREENS */
export const offerCreationScreens = {
  COMPANY_SELECTION: 'COMPANY_SELECTION',
  SCOPE_AND_ACCOUNTS: 'SCOPE_AND_ACCOUNTS',
  LIMITATIONS_AND_CONTACTS: 'LIMITATIONS_AND_CONTACTS',
  SIGNATURES: 'SIGNATURES',
  SENDING: 'SENDING',
};

/* OFFER CREATION ROUTES FOR EACH SCREEN */
export const offerCreationRoutesForEachScreen = {
  [offerCreationScreens.COMPANY_SELECTION]: 'company-selection',
  [offerCreationScreens.SCOPE_AND_ACCOUNTS]: 'order-scope-and-accounts',
  [offerCreationScreens.LIMITATIONS_AND_CONTACTS]: 'limitations-and-contacts',
  [offerCreationScreens.SIGNATURES]: 'signing',
  [offerCreationScreens.SENDING]: 'sending',
};

/* OFFER CREATION ROUTES ORDERED ARRAY */
export const offerCreationRoutesOrderedArray = [
  offerCreationRoutesForEachScreen.SCOPE_AND_ACCOUNTS,
  offerCreationRoutesForEachScreen.LIMITATIONS_AND_CONTACTS,
  offerCreationRoutesForEachScreen.SIGNATURES,
  offerCreationRoutesForEachScreen.SENDING,
];

/* OFFER SERVER STATUSES */
export const offerServerStatuses = {
  INITIATED: 'INITIATED',
  READY_TO_AUCTIONS: 'READY_TO_AUCTIONS',
  ACCEPTED_BY_UNDERWRITER: 'ACCEPTED_BY_UNDERWRITER',
  REJECTED_BY_UNDERWRITER: 'REJECTED_BY_UNDERWRITER',
  EDITING_MODE: 'EDITING_MODE',
};

/* OFFER PROGRESS CHECKPOINTS */
export const offerProgressCheckpoints = {
  scopeAndAccountsFilled: 'scopeAndAccountsFilled',
  limitationsAndContactsFilled: 'limitationsAndContactsFilled',
  sentToSignatories: 'sentToSignatories',
  signatoriesSigned: 'signatoriesSigned',
};
