import { useContext, useState } from 'react';
import { Container } from 'react-bootstrap';

import { OfferCreationContext } from 'context/offerCreation';

import { SensitivityTable } from '../SensitivityTable';

import { Footer } from './Footer';
import { Header } from './Header';

export const YieldAnalysis = () => {
  const { offer } = useContext(OfferCreationContext);

  const [lastTimeUpdated, setLastTimeUpdated] = useState(Date.now());
  const [refetchSensitivityTableData, setRefetchSensitivityTableData] = useState(false);

  return (
    <Container className="flex flex-col gap-4 mb-[150px]">
      <Header lastTimeUpdated={lastTimeUpdated} onRefreshClick={() => setRefetchSensitivityTableData(true)} />
      <section className="bg-yellow-50 rounded-lg border border-orange-400">
        <Container>
          <SensitivityTable
            offer={offer}
            refetchSensitivityTableData={refetchSensitivityTableData}
            setRefetchSensitivityTableData={setRefetchSensitivityTableData}
            setLastTimeUpdated={setLastTimeUpdated}
          />
        </Container>
      </section>
      <Footer />
    </Container>
  );
};
