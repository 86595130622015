import findKey from 'lodash/findKey';

import {
  offerCreationRoutesForEachScreen,
  offerCreationScreens,
  offerProgressCheckpoints,
  offerServerStatuses,
} from 'shared/const/offerCreationStages';

export const isRouteAllowed = (offer, route) => {
  const thisRouteStage = findKey(offerCreationRoutesForEachScreen, (routeValue) => routeValue === route);
  const isOfferInitiated = offer?.status === offerServerStatuses.INITIATED;
  switch (thisRouteStage) {
    case offerCreationScreens.COMPANY_SELECTION:
      return !offer;
    case offerCreationScreens.SCOPE_AND_ACCOUNTS:
      return isOfferInitiated;
    case offerCreationScreens.LIMITATIONS_AND_CONTACTS:
      return isOfferInitiated && offer.progress?.[offerProgressCheckpoints.scopeAndAccountsFilled];
    case offerCreationScreens.SIGNATURES:
      return (
        isOfferInitiated &&
        offer.progress?.[offerProgressCheckpoints.scopeAndAccountsFilled] &&
        offer.progress?.[offerProgressCheckpoints.limitationsAndContactsFilled]
      );
    case offerCreationScreens.SENDING:
      return (
        isOfferInitiated &&
        offer.progress?.[offerProgressCheckpoints.scopeAndAccountsFilled] &&
        offer.progress?.[offerProgressCheckpoints.limitationsAndContactsFilled] &&
        offer.progress?.[offerProgressCheckpoints.sentToSignatories]
      );
    default:
      console.log(`Route not found ${thisRouteStage}`);
      return false;
  }
};
