export const auctionTypes = {
  INTEREST: 'INTEREST',
  PRICE: 'PRICE',
  GAP: 'GAP',
};

export const unitTypes = {
  BONDS: 'BONDS',
  STOCKS: 'STOCKS',
  CONVERTIBLES: 'CONVERTIBLES',
  PARTICIPATION_UNITS: 'PARTICIPATION_UNITS',
  REJECTED_COMMITMENTS: 'REJECTED_COMMITMENTS',
  OPTIONS: 'OPTIONS',
  COMMERCIAL_BONDS: 'COMMERCIAL_BONDS',
  BUY_OPTIONS: 'BUY_OPTIONS',
};

export const bondTypes = [unitTypes.BONDS, unitTypes.COMMERCIAL_BONDS, unitTypes.CONVERTIBLES];
