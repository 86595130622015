import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

import { OfferCreationContext } from 'context/offerCreation';

import { deleteDividedAccount, getCompanyDividedAccounts } from 'shared/apis';
import { sortDirections } from 'shared/const/sortDirections';

import { TableQuery } from 'components/UI/TableQuery';

import { Columns } from './columns';

export const SavedAccountsTable = ({ selected, setSelected, setQueryData }) => {
  const queryClient = useQueryClient();
  const { offer } = useContext(OfferCreationContext);
  const companyId = offer.initiation.investingCompany.companyId;

  const { mutate: deleteAccountMutation } = useMutation({
    mutationKey: 'deleteAccount',
    mutationFn: deleteDividedAccount,
    onSuccess: () => {
      queryClient.invalidateQueries('companyDividedAccounts');
      setSelected([]);
    },
  });

  const deleteAccount = (dividedAccountId) => {
    deleteAccountMutation({ dividedAccountId, companyId });
  };

  return (
    <TableQuery
      queryKey={'companyDividedAccounts'}
      queryFn={() => getCompanyDividedAccounts(companyId)}
      Columns={Columns({ deleteAccount })}
      defaultSort={{ 'employee.firstName': sortDirections.ASC }}
      withSelection={true}
      selected={selected}
      setSelected={setSelected}
      setQueryData={setQueryData}
      withPagination={false}
      withSort={false}
      withSearch={false}
    />
  );
};
