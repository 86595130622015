const { OfferCreationContext } = require('context/offerCreation');
const { useContext, useMemo } = require('react');
const { Container } = require('react-bootstrap');
const { useTranslation } = require('react-i18next');

export const Footer = () => {
  const { offer } = useContext(OfferCreationContext);
  const sensitivityCalcParams = offer.initiation.auctionDetails.sensitivityCalcParams;

  const { t } = useTranslation();

  const commitmentFeeInfoText = useMemo(() => {
    const isCommitmentFeeIncluded = sensitivityCalcParams.isCommitmentFeeIncluded;
    const commitmentFeeText = isCommitmentFeeIncluded
      ? t('components.YieldAnalysis.commitmentFeeIncluded')
      : t('components.YieldAnalysis.commitmentFeeNotIncluded');

    return commitmentFeeText;
  }, [t, sensitivityCalcParams.isCommitmentFeeIncluded]);

  const calcTypeInfoText = useMemo(() => {
    switch (sensitivityCalcParams.governmentYieldCalculationMethod) {
      case 'linear':
        return t('components.YieldAnalysis.linear');
      case 'nelson_siegel_svensson':
        return t('components.YieldAnalysis.nelsonSiegelSvensson');
      default:
        console.error('Unknown governmentYieldCalculationMethod');
        return '';
    }
  }, [t, sensitivityCalcParams.governmentYieldCalculationMethod]);

  const statsInfoText = useMemo(() => {
    return `${commitmentFeeInfoText}; ${calcTypeInfoText}`;
  }, [commitmentFeeInfoText, calcTypeInfoText]);

  return (
    <footer>
      <Container>
        <p className="before:content-['*']">{statsInfoText}</p>
        <p className="before:content-['**']">{t('components.YieldAnalysis.disclaimer')}</p>
      </Container>
    </footer>
  );
};
