import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import { updateUnderwriter } from 'shared/apis';
import { schema } from 'shared/schemas/underwriter';

import UnderwriterForm from 'components/Admin/UnderwritersTable/Form';

const EditRHF = ({ selectedUnderwriter, show, onClose }) => {
  const queryClient = useQueryClient();

  const {
    mutate: mutateEditUnderwriter,
    isPending: isEditUnderwriterLoading,
    isError: isEditUnderwriterError,
  } = useMutation({
    mutationFn: (data) => updateUnderwriter(selectedUnderwriter._id, data),
    onSuccess: () => {
      queryClient.invalidateQueries('getAllUnderwritersTable');
    },
  });

  const methods = useForm({
    defaultValues: {
      name: selectedUnderwriter.name,
      allowedHostnames: selectedUnderwriter.allowedHostnames?.map((allowedHostname) => ({ url: allowedHostname })) || [{ url: '' }],
      phone: selectedUnderwriter.phone,
      email: selectedUnderwriter.email,
      logo: selectedUnderwriter.logo,
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    let { name, allowedHostnames, phone, email, logo } = data;
    allowedHostnames = allowedHostnames.map((hostname) => hostname.url);

    const expectedPayload = {
      name,
      allowedHostnames,
      phone,
      email,
      logo,
    };

    mutateEditUnderwriter(expectedPayload);
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <UnderwriterForm onSubmit={onSubmit} show={show} onClose={onClose} isLoading={isEditUnderwriterLoading} isError={isEditUnderwriterError} />
    </FormProvider>
  );
};

export default EditRHF;
