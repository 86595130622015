import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import numbro from 'numbro';
import { Fragment, useCallback, useContext, useMemo } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';
import { OfferCreationContext } from 'context/offerCreation';

import { initiateOffer } from 'shared/apis';
import { auctionTypes } from 'shared/const/auctions';
import { contractStatues } from 'shared/const/contractStatues';
import { buildAuctionTitle, getLocalizedAuctionType } from 'shared/utils/auctions';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';
import { calculateLimits, getOfferScope } from 'shared/utils/offers';

import { AuctionAttachments } from 'components/common/AuctionAttachments';
import { StatusCell } from 'components/LiveAuctionCard/StatusCell';
import Box from 'components/UI/Box';
import { Tooltip } from 'components/UI/Tooltip';

import { AuctionTimer } from './AuctionTimer';
import ManageOfferDropdown from './ManageOfferDropdown';

dayjs.extend(duration);

const LiveAuctionCard = ({ auction, offers, employmentState }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setOffer, offerDataForAnalytics } = useContext(OfferCreationContext);
  const { addAlert } = useContext(AlertContentContext);
  const { canCreateOffersByCompany } = useContext(AuthContext);

  // Actions
  const { mutate: initiateOfferWithCompany } = useMutation({
    mutationKey: 'initiateOffer',
    mutationFn: initiateOffer,
    onSuccess: (data) => {
      setOffer(data);
      navigate(`/user/offers/create/order-scope-and-accounts/${data._id}`);
    },
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
    },
  });

  const auctionRequirementsText = useMemo(() => {
    const { biddingLimitations, unitStructures } = auction;
    const { minPrice, maxInterest } = calculateLimits(biddingLimitations, unitStructures);
    switch (auction.type) {
      case auctionTypes.PRICE:
        if (!minPrice) {
          return t('components.LiveAuctionCard.labels.noMinPrice');
        }
        return numbro(minPrice).format('0,0$');
      case auctionTypes.INTEREST:
        if (!maxInterest) {
          return t('components.LiveAuctionCard.labels.noMaxInterest');
        }
        return numbro(maxInterest / 100).format('0.0%');
      case auctionTypes.GAP:
        if (!maxInterest) {
          return t('components.LiveAuctionCard.labels.noMaxGap');
        }
        return numbro(maxInterest / 100).format('0.0%');
      default:
        throw new Error(`Unknown auction type: ${auction.type}`);
    }
  }, [auction, t]);

  const submit = (companyId) => {
    const payload = {
      companyId,
      auctionId: auction._id,
    };
    initiateOfferWithCompany(payload);
  };

  const formatCloseTime = useCallback(
    (closeTime) => {
      const duration = dayjs.duration(dayjs(closeTime).diff(dayjs()));
      const days = duration.days();
      let formattedDays = '';
      switch (days) {
        case 0:
          formattedDays = t('components.LiveAuctionCard.labels.today');
          break;
        case 1:
          formattedDays = t('components.LiveAuctionCard.labels.tomorrow');
          break;
        case 2:
          formattedDays = t('components.LiveAuctionCard.labels.dayAfterTomorrow');
          break;
        default:
          formattedDays = t('components.LiveAuctionCard.labels.inDays', { days });
          break;
      }
      return `${formattedDays}${dayjs(closeTime).format('HH:mm')}`;
    },
    [t],
  );

  const auctionDescription = useMemo(() => {
    return (
      <span>
        <span>
          <span>
            <strong>{t('components.LiveAuctionCard.labels.criteria')}:</strong> {getLocalizedAuctionType(auction.type, t)}: {auctionRequirementsText},{' '}
          </span>
          <span>
            <strong>{t('components.LiveAuctionCard.labels.commitmentFee')}:</strong>{' '}
            {numbro(parseFloat(auction.commitmentDiscount) / 100).format('0.00%')},{' '}
          </span>
          <span>
            <strong>{t('components.LiveAuctionCard.labels.underwriter')}:</strong> {auction.underwriter.name},{' '}
          </span>
          <span>
            <strong>{t('components.LiveAuctionCard.labels.closingTime')}:</strong> {formatCloseTime(auction.closeTime)}{' '}
          </span>
        </span>
        <AuctionTimer closeTime={auction.closeTime} />
      </span>
    );
  }, [auction, auctionRequirementsText, formatCloseTime, t]);

  const InitiatorCell = (offer) => {
    const { initiator } = offer.initiation;
    const { firstName, lastName } = initiator;
    const initiatorName = `${firstName} ${lastName}`;
    const initiatorEmail = initiator.email;
    const Trigger = <span>{initiatorName}</span>;

    return <Tooltip Content={initiatorEmail} Trigger={Trigger} />;
  };

  const buildOfferCells = (offer) => (
    <>
      <td className="font-medium">
        {numbro(getOfferScope(offer)).format({
          output: 'currency',
          mantissa: 0,
          thousandSeparated: true,
        })}
      </td>
      <td>{InitiatorCell(offer)}</td>
      <td>
        <StatusCell offer={offer} />
      </td>
      <td>
        <ManageOfferDropdown auction={auction} offer={offer} offerDataForAnalytics={offerDataForAnalytics} />
      </td>
    </>
  );

  const auctionAttachments = useMemo(() => {
    return (
      <div className="flex gap-1">
        {t('components.liveAuctionCard.labels.attachments')}: <AuctionAttachments auction={auction} direction="row" addCommasSeparator />
      </div>
    );
  }, [t, auction]);

  if (!auction) {
    return (
      <Box>
        <div className="flex flex-col">
          <h3> {t('components.LiveAuctionCard.labels.noAuctions')}</h3>
        </div>
      </Box>
    );
  }

  return (
    <Box>
      <div className="flex flex-col">
        <h3> {buildAuctionTitle({ auction, t })}</h3>
        <p className="small mb-3">
          {auctionAttachments}
          {auctionDescription}
        </p>
        <Table className="text-center">
          <thead>
            <tr>
              <th>{t('components.LiveAuctionCard.table.inNameOf')}</th>
              <th>{t('components.LiveAuctionCard.table.orderScope')}</th>
              <th>{t('components.LiveAuctionCard.table.orderCreator')}</th>
              <th>{t('components.LiveAuctionCard.table.orderStatus')}</th>
              <th></th> {/* Action */}
            </tr>
          </thead>
          <tbody>
            {employmentState[contractStatues.ACTIVE].map((contract) => {
              const relatedOffers = offers.filter((offer) => offer.initiation.investingCompany.companyId === contract.companyId);
              const hasRelatedOffers = relatedOffers.length > 0;
              return (
                <Fragment key={contract._id}>
                  <tr>
                    {/*                     
                    height: 0 is a hack to be able to set the flexbox within to be 100% height
                    width: 1 is a hack to make the column hug the content
                    */}
                    <td rowSpan={relatedOffers?.length || 1} className="h-0 w-1 border-e-2">
                      <div className="flex justify-between align-items-center text-start h-100 mx-3 min-w-[400px]">
                        {contract.company.name}
                        {canCreateOffersByCompany(contract.companyId) && (
                          <Button
                            variant={hasRelatedOffers ? 'outline-primary' : 'primary'}
                            onClick={() => {
                              submit(contract.companyId);
                            }}
                            className="min-w-[164px] !text-sm"
                          >
                            {relatedOffers.length > 0
                              ? t('components.LiveAuctionCard.buttons.createAnother')
                              : t('components.LiveAuctionCard.buttons.createOffer')}
                          </Button>
                        )}
                      </div>
                    </td>

                    {/* no offers */}
                    {!hasRelatedOffers && (
                      <>
                        <td>-</td>
                        <td>-</td>
                        <td>
                          לא הוזנה הצעה
                          {/* todo: enhance */}
                        </td>
                        <td></td> {/* empty action*/}
                      </>
                    )}

                    {/* first offer  */}
                    {relatedOffers.length > 0 && buildOfferCells(relatedOffers[0])}
                  </tr>

                  {/* additional offers */}
                  {relatedOffers.length > 1 && relatedOffers.slice(1).map((offer) => <tr key={offer._id}>{buildOfferCells(offer)}</tr>)}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Box>
  );
};

export default LiveAuctionCard;
