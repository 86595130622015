import { useQuery } from '@tanstack/react-query';
import numbro from 'numbro';
import { useEffect, useMemo } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getSensitivityDataForRange } from 'shared/apis';

import { useSensitivityTableForm } from 'hooks/useSensitivityTableForm';

import { PivotTable } from 'components/UI/PivotTable';

import { Header } from './Header';
import { useRows } from './useRows';

export const SensitivityTable = ({ offer, refetchSensitivityTableData, setRefetchSensitivityTableData, setLastTimeUpdated }) => {
  const rows = useRows();
  const { t } = useTranslation();
  const { methods, minLimit, maxLimit, isValidState } = useSensitivityTableForm({ offer });

  const auctionDetails = offer.initiation.auctionDetails;
  const auctionId = auctionDetails.auctionId;
  const step = auctionDetails.biddingLimitations.priceGap;
  const auctionType = auctionDetails.type;

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['getSensitivityDataForRange', minLimit, maxLimit, auctionId],
    queryFn: async () => {
      const res = await getSensitivityDataForRange(auctionId, { min: minLimit, max: maxLimit });
      return res || [];
    },
    enabled: isValidState,
    staleTime: 1000 * 60 * 1,
  });

  useEffect(() => {
    if (refetchSensitivityTableData && !isRefetching) {
      const bondFlowResTime = data?.[0].bondFlowResTime || Date.now();
      refetch();
      setLastTimeUpdated(bondFlowResTime);
      setRefetchSensitivityTableData(false);
    }
  }, [data, refetch, isRefetching, refetchSensitivityTableData, setLastTimeUpdated, setRefetchSensitivityTableData]);

  const showLoading = isLoading || isRefetching;

  const columnFormatter = useMemo(() => {
    switch (auctionType) {
      case 'INTEREST':
      case 'GAP':
        return (val) => `${val}%`;
      case 'PRICE':
        let format = '0,0$';

        if (step < 1) {
          format = '0,0.00$';
        }

        return (val) => numbro(val).format(format);
      default:
        return (val) => val;
    }
  }, [auctionType, step]);

  return (
    <FormProvider {...methods}>
      <Container className="mb-3">
        <Header offer={offer} />
      </Container>
      {showLoading ? (
        <Container className="d-flex justify-center align-items-center">
          <Spinner animation="border" size="xl" />
        </Container>
      ) : (
        <PivotTable
          data={data}
          rowsColumnText={t(`common.auctionTypes.${offer.initiation.auctionDetails.type}`)}
          columnKey="limit" // The limits will be the columns
          columnFormatter={columnFormatter}
          rowKeys={rows}
          fixed
        />
      )}
    </FormProvider>
  );
};
