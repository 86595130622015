const round = (value, precision) => {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
};

const getPrecision = (value) => {
  const valueString = value.toString();
  if (valueString.includes('.')) {
    return valueString.split('.')[1].length;
  }
  return 0;
};

export const calculateSteps = (min, max, step) => {
  const limits = [];
  const precision = getPrecision(step);

  for (let i = 0; i <= Math.round((max - min) / step); i++) {
    const limit = round(min + i * step, precision);
    limits.push(limit);
  }

  return limits;
};
