import dayjs from 'dayjs';
import some from 'lodash/some';
import { Fragment, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'context/Auth';

import { customLocalStorage } from 'shared/customLocalStorage';

import { AgreementModal } from 'components/UI/ProtectedRoute/AgreementModal';

const ProtectedRoute = ({ allowedRoles, children }) => {
  const {
    authContextLoaded,
    isTokenLoadedToContext,
    lastTimeVerified,
    userId,
    signedCurrentAgreementVersion,
    systemRole,
    isSBAdmin,
    activeContracts,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const isTFAValidated = () => {
      const tokenVerified = dayjs(lastTimeVerified).add(process.env.REACT_APP_VERIFICATION_EXPIRATION_TIME, 'minute').isAfter(dayjs());
      return tokenVerified;
    };

    const isExistingUser = () => {
      return !!userId;
    };

    const isLoggedIn = () => {
      const TFAValidated = isTFAValidated();
      const existingUser = isExistingUser();
      const loggedIn = TFAValidated && existingUser;
      return loggedIn;
    };

    const redirectLogic = () => {
      if (!isLoggedIn()) {
        return '/authentication';
      }

      return isSBAdmin ? '/admin' : '/user';
    };

    const isAllowed = () => {
      const loggedIn = isLoggedIn();
      const roleAllowed = !!allowedRoles && allowedRoles.includes(systemRole);
      /** users are considered confirmed only after they got accepted to a company  */
      const userConfirmed = isSBAdmin || some(activeContracts);

      return loggedIn && roleAllowed && userConfirmed;
    };

    if (!authContextLoaded) return;
    if (!isTokenLoadedToContext) {
      if (customLocalStorage.getAccessToken()) {
        /**wait for next call */
        return;
      } else {
        /**no way user authenticated, navigate away */
        navigate(redirectLogic());
      }
    }

    if (!isAllowed()) {
      navigate(redirectLogic());
    }
  }, [authContextLoaded, allowedRoles, navigate, systemRole, userId, lastTimeVerified, isSBAdmin, isTokenLoadedToContext, activeContracts]);

  if (!isTokenLoadedToContext) return null;

  return <Fragment>{signedCurrentAgreementVersion ? children : <AgreementModal />}</Fragment>;
};

export default ProtectedRoute;
